<script lang="ts" setup>
import type { homeKeyFigures } from '~/interfaces/home.interface'

const props = defineProps<{
  content?: homeKeyFigures
}>()

const items = ref<{ [key: number]: number }>({})

if (props.content && props.content.items) {
  props.content.items.forEach((item, index) => {
    if (item.number)
      items.value[index] =
        item.number > 10
          ? Math.round((item.number - 10) * 10) / 10
          : item.number
  })
}

const animateNumbers = () => {
  if (props.content && props.content.items) {
    props.content.items.forEach((item, index) => {
      if (item.number > 10) {
        let actual = 0
        const interval = setInterval(() => {
          items.value[index] = Math.round((item.number - 10 + actual) * 10) / 10
          actual += 1
          if (actual > 10) clearInterval(interval)
        }, 50)
      }
    })
  }
}

const target = ref(null)
const targetIsVisible = ref(false)

useIntersectionObserver(target, ([{ isIntersecting }]) => {
  targetIsVisible.value = isIntersecting
  if (isIntersecting) animateNumbers()
})
</script>

<template>
  <section
    v-if="content"
    id="key-figures"
    class="mt-112 bg-[#F9F9F9] py-20 lg:py-124"
  >
    <UContainer ref="target">
      <AkRow v-if="content.items" justify="center">
        <AkCol
          v-for="(item, index) in content.items"
          :key="item.id"
          :cols="12"
          :cols-md="6"
          :cols-lg="4"
        >
          <div class="flex flex-col justify-center">
            <div v-if="item.title" class="text-primary text-h2">
              {{ item.title }}
            </div>
            <div v-else class="text-primary text-h2">
              <span>{{ item.number_prefix }} {{ items[index] }}</span>
              {{ item.number_suffix }}
            </div>
            <div v-if="item.text" class="my-32 h-2 w-62 bg-secondary" />
            <div v-if="item.text" class="text-primary text-h5">
              {{ item.text }}
            </div>
          </div>
        </AkCol>
      </AkRow>
    </UContainer>
  </section>
</template>
